import { ctaClickParams } from '@wix/bi-logger-any-marketing-lp/v2/types';

type BIEventsType = Record<BI_Events_Error_Key, ctaClickParams>;

export const BI_Events: BIEventsType = {
  '401': {
    buttonName: 'Log in  and try again',
    pageName: 'Classic 401 Error page',
  },
  '403': {
    buttonName: 'Go to Homepage',
    pageName: 'Classic 403 Error page',
  },
  '404': {
    buttonName: 'Go to Homepage',
    pageName: 'Classic 404 Error page',
  },
  '410': {
    buttonName: 'Go to Homepage',
    pageName: 'Classic 410 Error page',
  },
  '429': {
    buttonName: 'Go to Homepage',
    pageName: 'Classic 429 Error page',
  },
  '500': {
    buttonName: 'Check our status page for more information',
    pageName: 'Classic 500 Error page',
  },
  '502': {
    buttonName: 'Check our status page for more information',
    pageName: 'Classic 502 Error page',
  },
  '503': {
    buttonName: 'Check our status page for more information',
    pageName: 'Classic 503 Error page',
  },
  '504': {
    buttonName: 'Check our status page for more information',
    pageName: 'Classic 504 Error page',
  },
};
