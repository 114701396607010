import React, { FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import s from './App.scss';
import ErrorLayout from './ErrorLayout';
import BackgroundImage from './BackgroundImage';
import dataHooks from '../constants/data-hooks';
import classNames from 'classnames';
import { BrandStyle } from '../constants';

interface AppProps extends WithTranslation {
  brandStyle: BrandStyle;
}

const App: FC<AppProps> = ({ brandStyle = 'classic' }) => {
  const isClassic = brandStyle === 'classic';
  const isNotBranded = brandStyle === 'notBranded';
  const isWixel = brandStyle === 'wixel';
  return (
    <div
      className={classNames(s.root, {
        [s.wixel_background]: isWixel,
      })}
      data-hook={dataHooks.wixelBackground}
    >
      <div
        className={classNames(s.container, {
          [s.premium_container]: isNotBranded,
          [s.classic_container]: !isNotBranded,
        })}
        data-hook={dataHooks.errorApp}
      >
        <ErrorLayout isNotBranded />
        {isClassic ? <BackgroundImage /> : null}
      </div>
    </div>
  );
};

export default withTranslation()(App);
