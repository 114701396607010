import React from 'react';

export const splitAndMapToDivs = (input: string, regex: RegExp) => {
  return input
    .split(regex)
    .filter((line) => line.trim() !== '')
    .map((line, index) => <div key={index}>{line.trim()}</div>);
};

export const capitalizeFirstLettersOfWords = (inputString: string) => {
  const words = inputString.split(' ');
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return capitalizedWords.join(' ');
};
