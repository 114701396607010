import React, { FC } from 'react';
import s from './BackgroundImage.scss';
import dataHooks from '../../constants/data-hooks';
import LargeImage from '../../../assets/images/classicImage/LargeImage.svg';
import SmallImage from '../../../assets/images/classicImage/SmallImage.svg';
import useMediaQuery from '../../../hooks/resize-tab';

const BackgroundImage: FC = () => {
  const isTablet = useMediaQuery(
    '(min-width: 431px) and (orientation: portrait)',
  );
  return (
    <div className={s.svgContainer} data-hook={dataHooks.backgroundImage}>
      {!isTablet ? <LargeImage /> : <SmallImage />}
    </div>
  );
};

export default BackgroundImage;
