import { useMemo } from 'react';
import webBiLogger from '@wix/web-bi-logger';

export function useBiLogger() {
  const biLogger = useMemo(
    () =>
      webBiLogger
        .factory()
        .updateDefaults({ isMainCta: true, isValid: true })
        .logger(),
    [],
  );
  return biLogger;
}
