import React, { FC, useState } from 'react';
import s from './ErrorButton.scss';
import { useTranslation } from 'react-i18next';
import dataHooks from '../../../constants/data-hooks';
import { useErrorData } from '../../../../hooks/use-error-data';
import { useBiLogger } from '../../../../hooks/use-bi-logger';
import { reportError } from '../errorsMapping';
import classNames from 'classnames';
import { ArrowRight } from '@wix/wix-ui-icons-common';

interface ErrorButtonProps {
  link: string;
  label: string;
  isWixel?: boolean;
}

const ErrorButton: FC<ErrorButtonProps> = ({
  link,
  label,
  isWixel = false,
}) => {
  const { t } = useTranslation();
  const errorData = useErrorData();
  const biLogger = useBiLogger();
  const errorCode =
    (errorData?.errorCode.slice(0, 3) as BI_Events_Error_Key) || 'default';
  const [isWixelHovered, setIsWixelHovered] = useState(false);
  return (
    <a
      href={t(link)}
      data-hook={dataHooks.errorButton}
      onClick={() => reportError({ biLogger, biEventsErrorKey: errorCode })}
    >
      <button
        className={classNames(s.error_button, {
          [s.wixel_error_botton]: isWixel,
          [s.default_error_botton]: !isWixel,
        })}
        onMouseEnter={() => isWixel && setIsWixelHovered(true)}
        onMouseLeave={() => isWixel && setIsWixelHovered(false)}
      >
        {t(label)}
        {isWixel && (
          <ArrowRight
            className={classNames(s.arrow, {
              [s.arrowHidden]: !isWixelHovered,
              [s.arrowDisplay]: isWixelHovered,
            })}
            size="2vw"
          />
        )}
      </button>
    </a>
  );
};
export default ErrorButton;
