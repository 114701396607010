import React, { ReactNode, createContext, useContext } from 'react';
import { errorDataType } from '../errorData';

interface Props {
  children: ReactNode;
  errorData: errorDataType;
}

const ErrorDataContext = createContext<errorDataType | undefined>(undefined);

export const ErrorDataProvider = (props: Props) => {
  const { children, errorData } = props;

  return (
    <ErrorDataContext.Provider value={errorData}>
      {children}
    </ErrorDataContext.Provider>
  );
};

export const useErrorData = () => {
  return useContext(ErrorDataContext);
};
