import { ctaClick } from '@wix/bi-logger-any-marketing-lp/v2';
import { BI_Events } from '../../constants/biEvents';
import { factory } from '@wix/web-bi-logger';

type Logger = ReturnType<ReturnType<typeof factory>['logger']>;

interface errorPageDataType {
  title: string;
  description: string;
  buttonLabel: string;
  buttonLink: string;
  hideCTA?: boolean;
  nonBranded?: boolean;
}

export const reportError = ({
  biLogger,
  biEventsErrorKey,
}: {
  biLogger: Logger;
  biEventsErrorKey: BI_Events_Error_Key;
}) => {
  biLogger
    .report(
      ctaClick({
        ...BI_Events[biEventsErrorKey],
        pageUrl: window.location.href,
        targetUrl: errorMapping[biEventsErrorKey].buttonLink,
      }),
    )
    .catch((e: Error) => console.log(e));
};

const baseURL = window.location.origin;

export const errorMapping: { [key: string]: errorPageDataType } = {
  '400': {
    title: 'classic.error.400.title',
    description: 'classic.error.400.description',
    buttonLabel: '',
    buttonLink: '',
    hideCTA: true,
  },
  '400-NotBranded': {
    title: 'classic.error.400.title',
    description: 'classic.error.400.description',
    buttonLabel: '',
    buttonLink: '',
    hideCTA: true,
  },
  '401': {
    title: 'classic.error.401.title',
    description: 'classic.error.401.description',
    buttonLabel: 'classic.error.401.button.label',
    buttonLink: 'classic.error.401.button.link',
  },
  '401-NotBranded': {
    title: 'classic.error.401.title',
    description: 'classic.error.401.description',
    buttonLabel: 'classic.error.401.button.label.not-branded',
    buttonLink: baseURL,
  },
  '401-Wixel': {
    title: 'classic.error.401.title',
    description: 'classic.error.401.description',
    buttonLabel: 'classic.error.401.button.label.wixel',
    buttonLink: 'classic.error.401.button.link.wixel',
  },
  '403': {
    title: 'classic.error.403.title',
    description: 'classic.error.403.description',
    buttonLabel: 'classic.error.403.button.label',
    buttonLink: 'classic.error.403.button.link',
  },
  '403-NotBranded': {
    title: 'classic.error.403.title',
    description: 'classic.error.403.description',
    buttonLabel: 'classic.error.403.button.label.not-branded',
    buttonLink: baseURL,
  },
  '403-Wixel': {
    title: 'classic.error.403.title',
    description: 'classic.error.403.description',
    buttonLabel: 'classic.error.403.button.label.wixel',
    buttonLink: 'classic.error.403.button.link.wixel',
  },
  '404': {
    title: 'classic.error.404.title',
    description: 'classic.error.404.description',
    buttonLabel: 'classic.error.404.button.label',
    buttonLink: 'classic.error.404.button.link',
  },
  '404-NotBranded': {
    title: 'classic.error.404.title',
    description: 'classic.error.404.description',
    buttonLabel: 'classic.error.404.button.label.not-branded',
    buttonLink: baseURL,
  },
  '404-Wixel': {
    title: 'classic.error.404.title',
    description: 'classic.error.404.description',
    buttonLabel: 'classic.error.404.button.label.wixel',
    buttonLink: 'classic.error.404.button.link.wixel',
  },
  '410': {
    title: 'classic.error.410.title',
    description: 'classic.error.410.description',
    buttonLabel: 'classic.error.410.button.label',
    buttonLink: 'classic.error.410.button.link',
  },
  '410-NotBranded': {
    title: 'classic.error.410.title',
    description: 'classic.error.410.description',
    buttonLabel: 'classic.error.410.button.label.not-branded',
    buttonLink: baseURL,
  },
  '410-Wixel': {
    title: 'classic.error.410.title',
    description: 'classic.error.410.description',
    buttonLabel: 'classic.error.410.button.label.wixel',
    buttonLink: 'classic.error.410.button.link.wixel',
  },
  '429': {
    title: 'classic.error.429.title',
    description: 'classic.error.429.description',
    buttonLabel: 'classic.error.429.button.label',
    buttonLink: 'classic.error.429.button.link',
  },
  '429-NotBranded': {
    title: 'classic.error.429.title',
    description: 'classic.error.429.description',
    buttonLabel: 'classic.error.429.button.label.not-branded',
    buttonLink: baseURL,
  },
  '429-Wixel': {
    title: 'classic.error.429.title',
    description: 'classic.error.429.description',
    buttonLabel: 'classic.error.429.button.label.wixel',
    buttonLink: 'classic.error.429.button.link.wixel',
  },
  '500': {
    title: 'classic.error.500.title',
    description: 'classic.error.500.description',
    buttonLabel: 'classic.error.500.button.label',
    buttonLink: 'classic.error.500.button.link',
  },
  '500-NotBranded': {
    title: 'classic.error.500.title',
    hideCTA: true,
    description: 'classic.error.500.description',
    buttonLabel: '',
    buttonLink: '',
  },
  '502': {
    title: 'classic.error.502.title',
    description: 'classic.error.502.description',
    buttonLabel: 'classic.error.502.button.label',
    buttonLink: 'classic.error.502.button.link',
  },
  '502-NotBranded': {
    title: 'classic.error.502.title',
    hideCTA: true,
    description: 'classic.error.502.description',
    buttonLabel: '',
    buttonLink: '',
  },
  '503': {
    title: 'classic.error.503.title',
    description: 'classic.error.503.description',
    buttonLabel: 'classic.error.503.button.label',
    buttonLink: 'classic.error.503.button.link',
  },
  '503-NotBranded': {
    title: 'classic.error.503.title',
    hideCTA: true,
    description: 'classic.error.503.description',
    buttonLabel: '',
    buttonLink: '',
  },
  '504': {
    title: 'classic.error.504.title',
    description: 'classic.error.504.description',
    buttonLabel: 'classic.error.504.button.label',
    buttonLink: 'classic.error.504.button.link',
  },
  '504-NotBranded': {
    title: 'classic.error.504.title',
    hideCTA: true,
    description: 'classic.error.504.description',
    buttonLabel: '',
    buttonLink: '',
  },
  default: {
    title: 'classic.error-title',
    description: 'classic.error.default.description',
    buttonLabel: 'classic.error.default.button.label',
    buttonLink: 'classic.error.default.button.link',
  },
};
