import React, { FC } from 'react';
import s from './ErrorDescription.scss';
import { splitAndMapToDivs } from '../../../../utils/helper';
import { useTranslation } from 'react-i18next';
import dataHooks from '../../../constants/data-hooks';
import useMediaQuery from '../../../../hooks/resize-tab';
import classNames from 'classnames';

interface ErrorDescriptionProps {
  description: string;
  requestId?: string;
  isNotBranded?: boolean;
}

const ErrorDescription: FC<ErrorDescriptionProps> = ({
  description,
  requestId,
  isNotBranded,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 768px)'); // 768px is the breakpoint of Tablet
  const descriptionElements = isMobile
    ? description
    : splitAndMapToDivs(description, /(?<=\.|。)/);

  return (
    <>
      <div
        className={classNames(s.errorDescription, {
          [s.premiumErrorDescription]: isNotBranded,
        })}
        data-hook={dataHooks.errorDescription}
      >
        {descriptionElements}
      </div>
      {requestId && (
        <div className={s.requestId} data-hook={dataHooks.errorRequestId}>
          {t('classic.error.requestId')}: {requestId}
        </div>
      )}
    </>
  );
};
export default ErrorDescription;
